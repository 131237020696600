<template>
   <div @dblclick="clickAction()">
    <span v-if="!edite">{{jieduan_name}}</span>
    <el-input v-else v-model="jieduan_name" placeholder="请输入内容" size="mini" @change="
    changeAction(scope)"></el-input>
  </div>
</template>
<script>
export default {
  name:"EditeKC",
  props:["scope"],
  data(){
    console.log(this.scope);
    return {
      edite:false,
      jieduan_name:this.scope.jieduan_name,
      row:this.scope
    }
  },
  methods:{
    clickAction(){
      this.edite = true;
    },
    async changeAction(row){
        console.log(row);
        this.row.jieduan_name = this.jieduan_name;
        this.$datas.meta = {...this.row};
        let res = await this.$datas.updateZuoYeOne;
        console.log(res);
        this.edite = false;
    }
  }
}
</script>