<template>
  <div class="xiangmu">
    <div class="operate">
      <h3>课程作业检查列表</h3>
      <div class="btns">
        <i
          :class="['iconfont icon-add', { close: openAdd == true }]"
          @click="addXM(openAdd)"
        ></i>
      </div>
    </div>
    <div class="show_data" v-loading="loading">
      <XMAdd v-if="openAdd" />
      <el-table
        v-if="!openAdd"
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="className" label="班级" sortable width="180">
        </el-table-column>
        <el-table-column prop="date" label="检查日期" sortable width="180">
        </el-table-column>
        <el-table-column  label="阶段" sortable width="180">
          <template slot-scope="scope" v-if="!scope.row.children">
              <EditeKC :scope="scope.row.row"></EditeKC>
          </template>
        </el-table-column>
        <el-table-column prop="kecheng_name" label="项目"> </el-table-column>
        <el-table-column prop="zuoye_name" label="作业名称"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope" v-if="!scope.row.children">
            <i
              class="iconfont icon-xiugai btn"
              @click="handleEdit(scope.$index, scope.row)"
            ></i>
            <i
              class="iconfont icon-shanchu btn"
              @click="handleDelete(scope.$index, scope.row)"
            ></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
//导入项目添加组件
import XMAdd from "./KeCheng/addKC.vue";
import EditeKC from "./KeCheng/EditeKC.vue";
export default {
  name: "KeChengLive",
  data() {
    return {
      openAdd: false,
      tableData: [],
      loading:false
    };
  },
  async created() {
    this.loading = true;
    let classR = await this.$datas.home;
    //获取在校班级
    let classNames = classR.data.msg.data.map(item=>item.className);
    // 开始根据班级查询数据
    this.$datas.meta = {classNames}; 
    let res = await this.$datas.getZuoYe;
    this.filteData(res);
    this.loading = false;
  },
  watch: {
    async openAdd(newV) {
      if (!newV) {
        let res = await this.$datas.getZuoYe;
        this.filteData(res);
      }
    },
  },
  methods: {
    addXM(open) {
      this.openAdd = !open;
    },
    filteData(res) {
      let data = [];
      for (let i in res.data) {
        let item = res.data[i];
        let exist = false;
        for (let j in data) {
          if (data[j].className == item.bj.className) {
            //班级已存在
            data[j].children.push({
              id: item.bj.id + "-" + item.id,
              className: item.bj.className,
              date: item.date,
              jieduan_name: item.jieduan_name,
              kecheng_name: item.kecheng_name,
              zuoye_name: item.zuoye_name,
              zyj: item.zyj,
              row:item
            });
            exist = true;
            break;
          }
        }
        //数据不存在
        if (exist == false) {
          //该数据不存在
          data.push({
            id: item.bj.id,
            className: item.bj.className,
            children: [
              {
                id: item.bj.id + "-" + item.id,
                className: item.bj.className,
                date: item.date,
                jieduan_name: item.jieduan_name,
                kecheng_name: item.kecheng_name,
                zuoye_name: item.zuoye_name,
                zyj: item.zyj,
                row:item
              },
            ],
          });
        }
      }
      data.sort((item1, item2)=>{
          let name1 = item1.className.replace(/[A-Z]/g, '');
          let name2 = item2.className.replace(/[A-Z]/g, '');
          return name2 - name1;
      });
      this.tableData = data;
    },
    handleEdit(index, row) {
      localStorage.setItem("xms", JSON.stringify(row.bxm));
      this.$router.push({ name: "ZuoYeList", params:{id:row.id}});
    },
    async handleDelete(index, row) {
      this.$datas.meta = { id: row.id.split("-")[1] };
      await this.$datas.deleteZuoYe;
      for (let i in this.tableData) {
        let item = this.tableData[i];
        for (let j in item.children) {
          let item1 = item.children[j];
          if (row.id == item1.id) {
            item.children.splice(j, 1);
            break;
          }
        }
        if (item.children.length == 0) {
          //删除班级
          this.$datas.meta = { id: item.id };
          await this.$datas.deleteBanJi;
          this.tableData.splice(i, 1);
        }
      }
    },
  },
  components: {
    XMAdd,
    EditeKC
  },
};
</script>
<style lang="less" scoped>
.xiangmu {
  padding: 10px 20px;
  .operate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    h3 {
      font-size: 20px;
      color: #555;
    }
    .btns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        cursor: pointer;
        font-size: 20px;
        color: #555;
        transition: all 0.5s;
        &:hover {
          color: #aaff;
        }
      }
      .close {
        transform: rotateZ(45deg);
      }
    }
  }
  .show_data {
    .btn {
      cursor: pointer;
      margin: 0 10px;

      &:hover {
        color: #aaff;
      }
    }
  }
}
</style>
